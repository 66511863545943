import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import ScrollableJustifyTabs from "../../CommonElements/ScrollableJustifyTabs";
import { ilpLearnerTabs } from "../../utils/helper/helper";
import ILPAssessment from "../UserManagement/ILPAssessment";

import AssignedILP from "./AssignedILP";
import ILPCertification from "./ILPCertification";
import ILPCurriculum from "./ILPCurriculum";
import ILPOverview from "./ILPOverview";
import ILPSelfEnrollment from "./ILPSelfEnrollment";
import ILPSga from "./ILPSga";
import ILPTrainingHistory from "./ILPTrainingHistory";
import ILPUnMapped from "./ILPUnMapped";
import PendingSkillUpdate from "./PendingSkillUpdate";

import "./style.scss";

const MyLearningPlan = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [activeTab, setActiveTab] = useState<string>(statusParam || "overview");

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div className="justify-tabs-container">
            <ScrollableJustifyTabs
              tabs={ilpLearnerTabs}
              activeTab={activeTab}
              onTabClick={handleTabClick}
            />
          </div>
        </CardBody>
      </Card>

      {activeTab === "overview" && <ILPOverview />}
      {activeTab === "curriculum" && <ILPCurriculum />}
      {activeTab === "assigned_ilp" && <AssignedILP />}
      {activeTab === "self_enrollment" && <ILPSelfEnrollment />}
      {activeTab === "certification" && <ILPCertification />}
      {activeTab === "unmapped" && <ILPUnMapped />}
      {activeTab === "sga" && <ILPSga />}
      {activeTab === "pending_skill_update" && <PendingSkillUpdate />}
      {activeTab === "assessment" && <ILPAssessment />}
      {activeTab === "training_history" && <ILPTrainingHistory />}
    </div>
  );
};

export default MyLearningPlan;
