import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Row } from "reactstrap";

import { courseTypeMap, sourceTypeMap } from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import { LearningPlanCardData } from "../../../container/LearningPlanCourseCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getTrainingHistory } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  CourseType,
  ILT,
  NoDataText,
  Select,
  Status,
  VILT,
  WBT,
  Webinar,
} from "../../../utils/Constant";
import {
  courseFilterDropdownList,
  trainingHistoryColumns,
  trainingHistoryFilterList,
} from "../../../utils/helper/helper";

const ILPTrainingHistory = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { trainingHistory, loading } = useAppSelector(
    state => state.learningPlan
  );
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );

  useEffect(() => {
    (async () => {
      await dispatch(getTrainingHistory({ userId: userId, fyYear: fyYear }));
    })();
  }, [dispatch, userId]);

  useEffect(() => {
    fetchCourses();
  }, [selectedItems, trainingHistory]);

  useEffect(() => {
    dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
      })
    );
  }, [dispatch, fyYear, selectedItems]);

  const mapTrainingHistoryToLearningPlanCardData = (
    trainingHistoryData: any
  ): LearningPlanCardData[] => {
    return trainingHistoryData.history.map(course => {
      const domainName =
        trainingHistoryData.domain[course.id]?.map(
          domainItem => domainItem.name
        ) || [];
      return {
        courseId: course.id.toString(),
        domainName: domainName,
        status: course.status,
        badgeTextColor: true,
        courseName: course.course_name,
        completedOn: course?.completed_on,
        assignedBy: sourceTypeMap[course?.source_type],
        courseType: courseTypeMap[course?.course_type],
        knowledgeScore: course?.knowledge_percentage,
        skillScore: course?.skill_percentage,
      };
    });
  };

  const filterCourses = (courses, selectedItems) => {
    if (selectedItems.length === 0) {
      return courses;
    }
    return courses.filter(item =>
      selectedItems.includes(item.courseType.toUpperCase())
    );
  };

  const fetchCourses = () => {
    if (!trainingHistory?.history) {
      setSearchResults([]);
      return;
    }

    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);
    const filteredCourses = filterCourses(transformedCourses, selectedItems);

    setSearchResults(filteredCourses);
  };

  const handleSearch = (text: string) => {
    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);

    const filteredCourses = transformedCourses.filter(
      course =>
        course?.courseName?.toLowerCase()?.includes(text?.toLowerCase()) ||
        course?.courseType?.toLowerCase()?.includes(text?.toLowerCase()) ||
        course?.status?.toLowerCase()?.includes(text?.toLowerCase())
    );
    setSearchResults(filteredCourses);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    await dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
        course_type: values["course_type"]?.toString() || null,
        status: values["status"]?.toString() || null,
      })
    );
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    setSearchResults(mapTrainingHistoryToLearningPlanCardData(trainingHistory));
    await dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
      })
    );
  };

  const renderCourses = () => {
    if (loading) {
      return null;
    }

    if (trainingHistory?.history?.length === 0 || searchResults?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    return (
      <DataTable
        columns={trainingHistoryColumns}
        data={searchResults}
        pagination
        fixedHeader
        striped
      />
    );
  };

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  return (
    <>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end "
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                      {
                        label: Status,
                        key: "status",
                        tooltipText: `${Select} ${Status}`,
                        options: trainingHistoryFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {renderCourses()}
    </>
  );
};
ILPTrainingHistory.propTypes = {
  userId: PropTypes.number,
};

export default ILPTrainingHistory;
