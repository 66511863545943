import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Col,
  Row,
  Tooltip,
} from "reactstrap";

import { H4, MUIIcons, P } from "../../AbstractElements";
import "./style.scss";
import { TM } from "../../Api/constants";
import {
  CreatedBy,
  CreatedOn,
  ModifiedBy,
  ModifiedOn,
} from "../../utils/Constant";
import { formatCustomDate, sanitizeString } from "../../utils/helper/helper";

interface MasterDataAccordionProps {
  accordionItems: AccordionItemData;
  onAccordionClick?: (id: string) => void;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  loginData: any;
}

interface AccordionItemData {
  id: string;
  name: string;
  description: string;
  createdOn: string;
  modifiedOn?: string;
  modifiedBy?: string;
  createdBy?: string;
  depedentBadges?: DepedentBadgesData[];
}

interface DepedentBadgesData {
  id: number;
  badge: string;
  toolTip?: boolean;
  toolTipValues?: string;
  iconName?: any;
  className?: string;
}

const MasterDataAccordion: React.FC<MasterDataAccordionProps> = ({
  accordionItems,
  onAccordionClick = () => {
    return;
  },
  onEditClick,
  onDeleteClick,
  loginData,
}) => {
  const toolTipUniqueId = sanitizeString(accordionItems.id);
  const [open, setOpen] = useState<string | undefined>("0");
  const [tooltipOpen, setTooltipOpen] = useState<number | null>(null);

  const toggleTooltip = (id: number) => {
    setTooltipOpen(prevState => (prevState === id ? null : id));
  };

  const toggle = (id: string) =>
    open === id ? setOpen(undefined) : setOpen(id);

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem className="custom-master-accordion mb-3">
        <AccordionHeader
          targetId={accordionItems.id}
          className="d-flex flex-row align-items-center p-1"
        >
          <Col>
            <Row lg={12} className="mb-3">
              <Col lg={9} className="d-flex justify-content-start">
                <H4>{accordionItems.name}</H4>
              </Col>
              <Col lg={3} className="d-flex justify-content-end gap-2">
                {accordionItems?.depedentBadges?.map(item => (
                  <Badge
                    key={item.id}
                    className="text-gray custom-accordion-badge"
                  >
                    {item.iconName && (
                      <MUIIcons size={16} iconName={item.iconName} />
                    )}
                    {item.badge}
                    {item.toolTip && (
                      <>
                        <span
                          id={`LevelTooltipIcon${toolTipUniqueId}`}
                          className={`ml-2 pointer ${item.className}`}
                        >
                          <MUIIcons size={16} iconName="InfoOutlined" />
                        </span>
                        <Tooltip
                          placement="bottom"
                          isOpen={tooltipOpen === item.id}
                          target={`LevelTooltipIcon${toolTipUniqueId}`}
                          toggle={() => toggleTooltip(item.id)}
                        >
                          {item.toolTipValues}
                        </Tooltip>
                      </>
                    )}
                  </Badge>
                ))}
                <MUIIcons
                  className="primary-icon-color"
                  iconName={"EditOutlined"}
                  size={20}
                  onClick={() => onEditClick(accordionItems.id)}
                />
                {loginData?.currentRole === TM && (
                  <MUIIcons
                    className="primary-icon-color"
                    iconName={"DeleteOutlineOutlined"}
                    size={20}
                    onClick={() => onDeleteClick(accordionItems.id)}
                  />
                )}

                <MUIIcons
                  onClick={() => onAccordionClick(accordionItems.id)}
                  size={20}
                  iconName="ExpandMoreOutlined"
                />
              </Col>
            </Row>

            <Row lg={12} className={accordionItems.modifiedOn && "mb-3"}>
              {accordionItems.createdBy && (
                <Col lg={4} className="d-flex gap-2">
                  <div className="d-flex gap-1 text-gray align-items-center">
                    <MUIIcons
                      className="text-gray"
                      iconName="PersonOutlined"
                      size={17}
                    />
                    <P className="mb-0">
                      {CreatedBy}: {accordionItems.createdBy}
                    </P>
                  </div>
                </Col>
              )}

              <Col lg={4}>
                <div className="d-flex gap-1 text-gray align-items-center">
                  <MUIIcons
                    className="text-gray"
                    iconName="CalendarTodayOutlined"
                    size={17}
                  />
                  <P className="mb-0">
                    {CreatedOn}: {formatCustomDate(accordionItems.createdOn)}
                  </P>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="d-flex gap-2">
                {accordionItems.modifiedBy && (
                  <Col lg={4}>
                    <div className="d-flex gap-1 text-gray align-items-center">
                      <MUIIcons
                        className="text-gray"
                        iconName="PersonOutlined"
                        size={17}
                      />
                      <P className="mb-0">
                        {ModifiedBy}: {accordionItems.modifiedBy}
                      </P>
                    </div>
                  </Col>
                )}
                {accordionItems?.modifiedOn && (
                  <Col lg={4}>
                    <div className="d-flex gap-1 text-gray align-items-center">
                      <MUIIcons
                        className="text-gray"
                        iconName="CalendarTodayOutlined"
                        size={17}
                      />
                      <P className="mb-0">
                        {ModifiedOn}:{" "}
                        {formatCustomDate(accordionItems.modifiedOn)}
                      </P>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
        </AccordionHeader>
        <AccordionBody accordionId={accordionItems.id}>
          <div
            dangerouslySetInnerHTML={{
              __html: accordionItems.description,
            }}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default MasterDataAccordion;
