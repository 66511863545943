import { getFromLocalStorage, LOGGED_IN_USER } from "./localStorageutils";

const roles = {
  reporting_manager: {
    REQUEST_TABS: true,
    RAISE_REQUEST: true,
    EDIT_REQUEST: true,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: true,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: false,
      QUESTION_BANK: false,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: false,
      MAP_SGA: false,
      MAP_ASSESSMENT: false,
      ASSIGN_YEAR_TO_COURSE: true,
      ASSIGN_ILP: true,
      MODIFY_YEAR_TO_COURSE: true,
      APPROVE_MODIFIED_YEAR: false,
    },
    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: true,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: true,
    RM_ASSESSMENT_CATLOG: true,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: false,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: false,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: true,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: true,
    CALL_MASTER_API: true,
  },
  training_manager: {
    REQUEST_TABS: true,
    RAISE_REQUEST: true,
    EDIT_REQUEST: true,
    ADD_COURSE: true,
    REJECT_RM_DETAILS: true,
    TRAINER_BOND_DETAILS: true,
    VIEW_RM_REQUEST: true,
    APPROVE_STAGES: true,
    EDIT_COURSE_DETAILS: true,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: true,
    CREATE_ASSESSMENT: false,
    CREATE_QUESTION_BANK: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: true,
    CREATE_ASSESSMENT_CATLOG: true,
    MASTER_DATA: {
      ROOT: true,
      QUESTION_BANK: true,
      POLICY: true,
      CURRICULUM: true,
      SGA: true,
      DOMAIN: true,
      COMPETENCY: true,
      LEARNING_OUTCOME: true,
      INDUSTRY: true,
      JOB_ROLE: true,
      GRADE: true,
      CLASSROOM: true,
      LOCATION: true,
      REGION: true,
    },
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: true,
      MAP_ROLE_TO_USER: true,
      ADD_CURRICULUM: true,
      MAP_SGA: true,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: true,
      MODIFY_YEAR_TO_COURSE: true,
      APPROVE_MODIFIED_YEAR: true,
      MAP_ASSESSMENT: true,
    },

    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: true,
    REVOKE_EDITOR_ACCESS: true,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: true,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: true,
    LOAD_FACTOR_ACTION: true,
    UPSERT_COURSE_SCHEDULE: true,
    SCHEDULED_COURSE_LIST: true,
    ASSIGN_TRAINERS: true,
    ADD_NOMINEES: true,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: true,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: true,
    MARK_TRAINER_ATTENDANCE: true,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: true,
    UNMAP_SGA: true,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: true,
    CALL_MASTER_API: true,
  },
  training_admin: {
    REQUEST_TABS: false,
    RAISE_REQUEST: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: false,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    CREATE_QUESTION_BANK: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: true,
      QUESTION_BANK: false,
      POLICY: true,
      CURRICULUM: true,
      SGA: true,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: true,
      GRADE: true,
      CLASSROOM: true,
      LOCATION: true,
      REGION: true,
    },
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: true,
      MAP_ROLE_TO_USER: true,
      ADD_CURRICULUM: true,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: true,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: true,
      MAP_ASSESSMENT: true,
    },

    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: true,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: true,
    LOAD_FACTOR_ACTION: true,
    UPSERT_COURSE_SCHEDULE: true,
    SCHEDULED_COURSE_LIST: true,
    ASSIGN_TRAINERS: true,
    ADD_NOMINEES: true,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: true,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: true,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: true,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: true,
    CALL_MASTER_API: true,
  },
  hr_admin: {
    REQUEST_TABS: true,
    RAISE_REQUEST: true,
    EDIT_REQUEST: true,
    ADD_COURSE: true,
    REJECT_RM_DETAILS: true,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: true,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: true,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    CREATE_QUESTION_BANK: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: true,
      QUESTION_BANK: true,
      POLICY: false,
      CURRICULUM: false,
      SGA: true,
      DOMAIN: true,
      COMPETENCY: true,
      LEARNING_OUTCOME: true,
      INDUSTRY: true,
      JOB_ROLE: true,
      GRADE: true,
      CLASSROOM: true,
      LOCATION: true,
      REGION: true,
    },
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: true,
      MAP_SGA: true,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: true,
      MODIFY_YEAR_TO_COURSE: true,
      APPROVE_MODIFIED_YEAR: true,
      MAP_ASSESSMENT: true,
    },

    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: true,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: true,
    SCHEDULED_COURSE_LIST: true,
    ASSIGN_TRAINERS: true,
    ADD_NOMINEES: true,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: true,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: true,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: true,
  },
  sme: {
    REQUEST_TABS: false,
    RAISE_REQUEST: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: true,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: false,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      ADD_CURRICULUM: false,
      MAP_COURSE: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    RM_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: false,
      QUESTION_BANK: false,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: false,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: false,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: false,
  },
  internal_trainer: {
    REQUEST_TABS: true,
    RAISE_REQUEST: true,
    EDIT_REQUEST: true,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: true,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: true,
    MASTER_DATA: {
      ROOT: true,
      QUESTION_BANK: true,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: true,
      COMPETENCY: true,
      LEARNING_OUTCOME: true,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: true,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: true,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: true,
    SCHEDULE_COURSE_TABS: true,
    RATE_TRAINEE: true,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: true,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: true,
  },
  external_trainer: {
    REQUEST_TABS: false,
    RAISE_REQUEST: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: false,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: true,
    ADD_COMMENT: true,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: true,
    MASTER_DATA: {
      ROOT: true,
      QUESTION_BANK: true,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: true,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: true,
    SCHEDULE_COURSE_TABS: true,
    RATE_TRAINEE: true,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: true,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: false,
    MY_DEVELOPMENT_PLAN: {
      ROOT: false,
      MY_LEARNING_PLAN: false,
      SCHEDULED_COURSES: false,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: false,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: true,
  },
  internal_learner: {
    REQUEST_TABS: false,
    RAISE_REQUEST: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT_SUB_MENU: false,
    COURSE_MANAGEMENT: {
      ROOT: false,
      COURSE_REQUEST: false,
      COURSE_LIST: false,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    VIEW_TIME_LINE_COURSE: false,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: false,
      QUESTION_BANK: false,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: true,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: true,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: false,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: false,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: true,
    MY_DEVELOPMENT_PLAN: {
      ROOT: true,
      MY_LEARNING_PLAN: true,
      SCHEDULED_COURSES: true,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: true,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: false,
  },
  external_learner: {
    REQUEST_TABS: false,
    RAISE_REQUEST: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT_SUB_MENU: false,
    COURSE_MANAGEMENT: {
      ROOT: false,
      COURSE_REQUEST: false,
      COURSE_LIST: false,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    VIEW_TIME_LINE_COURSE: false,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: {
      ROOT: false,
      QUESTION_BANK: false,
      POLICY: false,
      CURRICULUM: false,
      SGA: false,
      DOMAIN: false,
      COMPETENCY: false,
      LEARNING_OUTCOME: false,
      INDUSTRY: false,
      JOB_ROLE: false,
      GRADE: false,
      CLASSROOM: false,
      LOCATION: false,
      REGION: false,
    },
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      MAP_ROLE_TO_USER: false,
      ADD_CURRICULUM: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR_TO_COURSE: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: true,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: true,
    APPROVE_SKILL_DOC: false,
    RM_ASSESSMENT_CATLOG: false,
    MENTORING: {
      ROOT: true,
      MANAGE_MENTORING: false,
      MENTOR: true,
      MENTEE: true,
    },
    LOAD_FACTOR: false,
    LOAD_FACTOR_ACTION: false,
    UPSERT_COURSE_SCHEDULE: false,
    SCHEDULED_COURSE_LIST: false,
    ASSIGN_TRAINERS: false,
    ADD_NOMINEES: false,
    APPROVE_SCHEDULE: false,
    SCHEDULE_COURSE_TABS: false,
    RATE_TRAINEE: false,
    GLOBAL_SETTINGS: false,
    MARK_TRAINER_ATTENDANCE: false,
    MARK_LEARNER_ATTENDANCE: false,
    NOMINATION_REQUEST: false,
    COURSE_CATALOG: false,
    MY_DEVELOPMENT_PLAN: {
      ROOT: true,
      MY_LEARNING_PLAN: true,
      SCHEDULED_COURSES: true,
    },
    UNMAP_CURRICULUM: false,
    UNMAP_SGA: false,
    RAISE_SGA_EXTENSION: true,
    APPROVE_SGA_EXTENSION: false,
    CALL_MASTER_API: false,
  },
};

export const Session = () => {
  return getFromLocalStorage(LOGGED_IN_USER);
};

export const hasPermissionToComponent = (componentPath: string) => {
  const session = Session();

  const keys = componentPath.split(".");

  if (session?.role) {
    let permission = roles[session.role];

    for (const key of keys) {
      if (permission && permission[key] !== undefined) {
        permission = permission[key];
      } else {
        return false;
      }
    }
    return !!permission;
  }

  return false;
};

export const getPermissionObject = (component: string) => {
  const session = Session();
  if (session?.role) {
    const permission = roles[session.role];
    if (permission && permission[component] !== undefined) {
      return permission[component];
    }
  }
};

export const isCurrentUserIsCourseOwner = id => {
  const session = Session();

  if (session?.role && session.id) {
    const hasPermission = hasPermissionToComponent(
      "MODIFY_PUBLISHED_ASSESSMENT"
    );
    if (hasPermission || session.id === id) {
      return true;
    }
  }

  return false;
};

export const currentUserId = () => {
  const session = Session();

  if (session?.id) {
    return session.id;
  }
  return null;
};
